<template>
    <v-form>
        <v-alert
          v-if="!_.isNil(summaryError)"
          dense
          type="error"
        >
            {{ summaryError }}
        </v-alert>

        <v-text-field 
            v-model="$v.form.isbn.$model"
            :error-messages="getErrors('form.isbn')"
            label="ISBN" 
            name="isbn" 
            placeholder=" "
            color="light-green" 
        ></v-text-field>

        <v-select
          v-model="$v.form.subject.$model"
          :error-messages="getErrors('form.subject')"
          required
          :items="subjects"
          label="Предмет"
        ></v-select>

        <v-select
          v-model="$v.form.grade.$model"
          :error-messages="getErrors('form.grade')"
          required
          :items="activeGrades"
          label="Класс"
        ></v-select>

        <v-row no-gutters>
            <v-col cols="auto">
                <v-subheader class="pl-0">Диапазоны номеров:</v-subheader>
            </v-col>
            <v-col>
                <v-btn 
                    left 
                    small 
                    dark 
                    :color="$const.color.primary"
                    @click.prevent.stop="addRange"
                >
                    <v-icon>mdi-plus</v-icon>
                    Добавить диапазон
                </v-btn>
            </v-col>
        </v-row>
        <v-row no-gutters v-for="(num, n) in $v.form.ranges.$each.$iter" :key="n">
            <v-col cols="5">
                <v-text-field 
                    v-model.number="num.start.$model"
                    label="Начальный номер" 
                    name="start" 
                    type="number" 
                    placeholder=" "
                    color="light-green" 
                    hide-details
                ></v-text-field>
                <div class="fs-12 lh-12 pt-2 red--text" v-if="num.start.$dirty && !num.start.required">Обязательно</div>
                <!-- <div class="error" v-if="!v.name.minLength">Name must have at least {{ v.name.$params.minLength.min }} letters.</div> -->
            </v-col>
            <v-spacer />
            <v-col cols="5">
                <v-text-field 
                    v-model.number="num.end.$model"
                    label="Конечный номер" 
                    name="end" 
                    type="number" 
                    placeholder=" "
                    color="light-green" 
                    hide-details
                ></v-text-field>
                <div class="fs-12 lh-12 pt-2 red--text" v-if="num.end.$dirty && !num.end.required">Обязательно</div>
            </v-col>
            <v-col cols="1" class="d-flex flex-row justify-center align-center">
                <v-btn v-if="allowRemoveRange" icon color="red" @click.prevent.stop="removeRange(n)">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-divider class="my-4" />

        <v-row justify="start" align="center">
            <v-col class="col-auto">
                <v-btn 
                    v-if="!waiting"
                    dark 
                    outlined 
                    :color="$const.color.primary" 
                    small
                    @click.prevent.stop="back"
                >
                    <v-icon left dark>
                        mdi-arrow-left
                    </v-icon>
                    Назад к списку
                </v-btn>
            </v-col>
            <v-spacer />
            <v-col class="col-auto">
                <v-btn 
                    dark 
                    :color="$const.color.primary"
                    small
                    :loading="waiting" 
                    @click.prevent.stop="assign"
                >
                    Сгенерировать
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'ShortCodeAssignForm',
    props: {
        model: {
            type: Object
        }
    },
    mixins: [errorMixin, saveMixin, validationMixin],
    data() {
        return {
            form: {
                isbn: '',
                subject: '',
                grade: '',
                ranges: [{
                    start: '',
                    end: ''
                }],
                start: '',
                end: ''
            },
            waiting: false
        };
    },
    computed: {
        ...mapState('license_type', {
            subjects: (state) => _.filter(state.subjects, subject => subject.value !== '*')
        }),
        ...mapGetters('license_type', {
            allGrades: 'grades'
        }),
        grades() {
            return _.filter(this.allGrades, grade => grade.value !== '*');
        }, 
        activeSubject() {
            return _.find(this.subjects, {value: _.get(this, 'form.subject')});
        },
        activeGrades() {
            const hasAll = _.includes(_.isArray(this.form.grade) ? this.form.grade : [this.form.grade], '*');
            return _.map(this.grades, (grade) => {
                if(hasAll)
                    grade['disabled'] = grade.value !== '*';
                else if(grade.value === '*')
                    grade['disabled'] = false;
                else
                    grade['disabled'] = !_.includes(_.get(this, 'activeSubject.grades', []), grade.value);
                return grade;
            })
        },
        allowRemoveRange() {
            return this.form.ranges.length > 1;
        }
    },
    validations() {
        return {
            form: {
                isbn: {
                    required
                },
                subject: {
                    required
                },
                grade: {
                    required
                },
                ranges: {
                    required,
                    $each: {
                        start: {
                            required
                        },
                        end: {
                            required
                        }
                    }
                }
            }
        }
    },
    methods: {
        addRange() {
            this.form.ranges.push({
                start: '',
                end: ''
            })
        },
        removeRange(n) {
            this.form.ranges.splice(n, 1);
        },
        async assign() {
            this.waiting = true;
            this.serverErrors = null;

            if (this.validate()) {
                const { success, error, data } = await this.$store.dispatch(`short_code/assign`, {
                    ...this.form
                });

                if(success)
                {
                    this.$emit('success', data);
                    this.back();
                    return ;
                }
                else
                    this.serverErrors = error;
            }

            this.waiting = false;
            return;
        },
    },
    watch: {
        ['form.subject']: {
            handler () {
                // if(!this.skip_subject_watch && _.get(this, 'form.data.grade', null) !== '*')
                 _.set(this, 'form.grade', null);

                // this.skip_subject_watch = false;
            },
            deep: true
        }
    },
}
</script>
<style scoped lang="scss">
.fs-12{
    font-size: 12px;
}
.lh-12{
    line-height: 12px;
}
</style>